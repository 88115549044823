import React, { useContext } from "react"
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome"
import {
  faPlusSquare,
  faMinusSquare,
} from "@fortawesome/free-regular-svg-icons"
import { Link } from "gatsby"
import { StoreContext } from "../../context/store-context"

function CollectionPanel({ breakpoint = "lg" }) {
  const { collectionOpen, setCollectionOpen } = useContext(StoreContext)
  let blockClass = `d-${breakpoint}-block`
  // const [open, setOpen] = useState(false)

  return (
    <div
    // style={{ position: 'relative' }}
    >
      <button
        aria-label="Expand Collection Menu"
        style={{
          position: "fixed",
          top: "50%",
          transform: "translateY(-50%)",
          zIndex: 100,
          userSelect: "none",
          cursor: "pointer",
        }}
        onClick={() => setCollectionOpen(!collectionOpen)}
      >
        <FontAwesomeIcon
          icon={!collectionOpen ? faPlusSquare : faMinusSquare}
          size="3x"
        />
      </button>
      <div
        className={`${
          collectionOpen ? "sidebar-wrapper " : "sidebar-wrapper-collapsed"
        }`}
      >
        <div className={`sidebar sidebar-left d-flex flex-wrap ${blockClass} `}>
          <Link
            activeStyle={{ backgroundColor: "var(--yellow)" }}
            style={{ display: "block", padding: "0 5px" }}
            to="/shop"
          >
            ALL
          </Link>
          <Link
            activeStyle={{ backgroundColor: "var(--yellow)" }}
            style={{ display: "block", padding: "0 5px" }}
            to="/shop/t-shirt"
          >
            T-SHIRTS
          </Link>
          <Link
            activeStyle={{ backgroundColor: "var(--yellow)" }}
            style={{ display: "block", padding: "0 5px" }}
            to="/shop/top"
          >
            TOP
          </Link>
          <Link
            activeStyle={{ backgroundColor: "var(--yellow)" }}
            style={{ display: "block", padding: "0 5px" }}
            to="/shop/headwear"
          >
            HATS
          </Link>
          {/* <Link
            activeStyle={{ backgroundColor: "var(--yellow)" }}

            style={{ display: "block", padding: "0 5px" }}
            to="/shop/bottom"
          >
            BOTTOMS
          </Link> */}
          <Link
            activeStyle={{ backgroundColor: "var(--yellow)" }}
            style={{ display: "block", padding: "0 5px" }}
            to="/shop/accessory"
          >
            ACCESSORIES
          </Link>
        </div>
      </div>
    </div>
  )
}

export default CollectionPanel
